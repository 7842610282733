import React from 'react'
import { Helmet } from 'react-helmet'
import NoiseBackground from '../../Components/NoiseBackground'
import ProjectCard from '../../Components/ProjectCard/ProjectCard'
import {CardsContainer, MyProjectContainer, MyProjectsHtml, ProjectTypeMenuContainer } from './MyProjectsElements'
import ProjectData from "../../Content/project_content.json"
import ToggleSwitch from '../../Components/ProjectsMenu/ProjectsMenu'
import { useState } from 'react'
import PageHeader from '../../Components/PageHeader/PageHeader'
import background from '../../Assets/svg/background-orange.svg';
import Cover from '../../Components/Cover'
import BackgroundImage from '../../Components/BackgroundImage'


function MyProjects() {

    const [state, setState] = useState("FRONTEND")
    // var grids = 0
    // var frames = 0
    const items = []

    function readCards() {
        ProjectData.projects.map((item, i) => (
            items.push(item.menu === state && (<ProjectCard title={item.title} image={item.image}
                type={item.type} technology={item.technology} icon={item.icon} button={item.button}
                git_link={item.git_link} site_link={item.site_link}/>))
            
        ));

        // items.forEach(element => {
        //     if (element !== false) {
        //         grids++;
        //     }
        // });

        // if (grids >= 3) {
        //     grids = 3
        //     frames = 3
        // } else if (grids === 2) {
        //     frames = 1
        // } else {
        //     frames = 1
        // }
    }
    

    function displayProjects() {
        return items;
    }

    return (
        <MyProjectsHtml>
            <BackgroundImage background={background}/>
            <NoiseBackground/>
            <Helmet>
                <style>{'body { background: #F97946; }'}</style>
            </Helmet>
                <MyProjectContainer>
                    <PageHeader title="MY PROJECTS" color="#FFD782"/>
                    <ProjectTypeMenuContainer>
                        <ToggleSwitch values={["FRONTEND", "BACKEND", "UI/UX"]} selected="FRONTEND" onChangeState={setState}/>
                    </ProjectTypeMenuContainer>
                    {readCards()}
                    <CardsContainer>
                            {/* {gridTemplateColumns: " repeat(" + {grids} + ", " + 1     + "fr)"} */}
                            {displayProjects()}
                    </CardsContainer>
                </MyProjectContainer>
                <Cover/>
        </MyProjectsHtml>
    )
}

export default MyProjects
