import styled from "styled-components";

export const AboutHtmlContainer = styled.html`
    margin-bottom: 40px;
`;

export const AboutContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center ;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 30px;
    z-index: 0;
`;

export const AboutContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-top: 20px;
  

  @media (min-width: 768px) {
        
  }

  @media (min-width: 1023px) {
    margin-top: 5vh;
    flex-direction: row;
  }   
`;

export const AboutTopContainer = styled.div`
  display: flex;
  margin-bottom: 10vh;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    justify-content: center;
  }

  @media (min-width: 1025px) {
    margin-top: 2vh
  }

  @media (min-width: 1023px) {
    @media (min-height: 1300px) {
        margin-right: -100px;
    }
  } 
`;

export const ProfilePicture = styled.img`
  width: 50%;
  height: auto;
  margin-left: 15px;
  box-shadow: 8px 10px #fff, 9px 11px #000, 
              9px 9px #000, 7px 11px #000,
              -9px -8px #fff, -10px -9px #000,
              -10px -7px #000, -8px -9px #000;

  @media (min-width: 768px) {
      width: 30%;
  }

  @media (min-width: 1025px) {
    margin-left: 15%;
    width: 50%;
  }            
  
  @media (min-width: 1023px) {
    @media (min-height: 1300px) {
        width: 50%;
    }
  } 
  
`

export const VerticalText = styled.h3`
  font-family: Candara;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: #fff;
  font-size: 45px;
  background-color: #000;
  padding: 2px;

  @media (max-width: 375px) {
    font-size: 20px;
  }

  @media (min-width: 375px) {
    font-size: 30px;
  }

  @media (min-width: 768px) {
      font-size: 40px;
  }
`;

export const AboutTextContainer = styled.div`
  @media (min-width: 1023px) {
    margin-right: 2vw;
  }     
`;