import React from 'react'
import {Helmet} from 'react-helmet';
import { AboutContainer, AboutContentContainer, AboutHtmlContainer, AboutTextContainer, AboutTopContainer, ProfilePicture, VerticalText } from './AboutMeElements'
import NoiseBackground from '../../Components/NoiseBackground';
import AboutMeData from '../../Content/aboutme_content.json';
import AboutTextBox from '../../Components/AboutTextBox/AboutTextBox';
import PageHeader from '../../Components/PageHeader/PageHeader';
import { motion } from "framer-motion"
import Cover from '../../Components/Cover';
import pageTransitionFade from '../../Constants/PageTransitionFade';
import BackgroundImage from '../../Components/BackgroundImage';
import background from '../../Assets/svg/background-yellow.svg';
import profileGif from '../../Assets/img/profile.gif'

function AboutMe() {

    function readText() {
        return (
        AboutMeData.about.map((item, i) =>(
            <AboutTextBox content1={item.content1} content2={item.content2} special={item.special} id={item.id} style={{zIndex:0}}/>
        )))
    }

    return (
        <AboutHtmlContainer> 
            <NoiseBackground/>
            <BackgroundImage background={background}/>
            <Helmet>
                <style>{'body { background:#FFD782; }'}</style>
            </Helmet>
            <AboutContainer>
                <PageHeader title="ABOUT ME" color="#03CEA4"/>
                <AboutContentContainer>
                    <AboutTopContainer>
                        <ProfilePicture src={profileGif}/>
                        <VerticalText>BASED IN ESTONIA</VerticalText>
                    </AboutTopContainer>
                    <AboutTextContainer>
                        <motion.div 
                            initial="hidden" 
                            animate="show" 
                            exit="exit" 
                            transition={{duration: 2}} 
                            variants={pageTransitionFade}>
                            {readText()}
                        </motion.div>
                    </AboutTextContainer>
                </AboutContentContainer>
            </AboutContainer>
            <Cover/>
           
        </AboutHtmlContainer>
    )
}

export default AboutMe
