import React from 'react'
import { CardContainer, CardHeader, CardImage, CardTextContainer, TypeText, ProjectLinksContainer, VisitSiteButton, GitImage, Underlined} from './ProjectCardElements'
import GithubIcon from '../../Assets/svg/github.svg';
import GitlabIcon from '../../Assets/svg/gitlab.svg';
import BlankIcon from '../../Assets/img/blank.png';
import { motion } from 'framer-motion';

function ProjectCard(props) {

    function showIcon(icon) {
        switch(icon) {
            case "github":
                return <GitImage src={GithubIcon}/>;
            case "gitlab":
                return <GitImage src={GitlabIcon}/>;
            default:
                return <GitImage src={BlankIcon}/>;
        }
    }

    function showButton(button) {
        switch(button) {
            case false:
                return <VisitSiteButton style={{visibility:'hidden'}}></VisitSiteButton>;
            default:
                return <VisitSiteButton>VISIT SITE</VisitSiteButton>;
        }
    }

    return (
        <CardContainer style={{visibility: props.visibility}} as={motion.div} initial={{opacity: 0}} animate={{
            opacity: 1,  
            borderRadius: ["10%", "50%", "30%", "10%", "20%", "2%"],
          }}
        transition={{ duration: 0.5 }}>
            <CardHeader>{props.title}</CardHeader>
            <CardImage src={props.image}/>
            <CardTextContainer>
                <TypeText><Underlined>TYPE</Underlined>: {props.type}</TypeText>
                <TypeText><Underlined>TECHNOLOGY</Underlined>: {props.technology}</TypeText>
            </CardTextContainer>
            <ProjectLinksContainer>
                <a href={props.site_link} target="_blank" rel="noopener noreferrer">{showButton(props.button)}</a>
                <a href={props.git_link} target="_blank" rel="noopener noreferrer">{showIcon(props.icon)}</a>
            </ProjectLinksContainer>  
        </CardContainer>
    )
}

export default ProjectCard
