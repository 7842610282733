import React from 'react'
import {Helmet} from 'react-helmet';
import { ChangingContentText, ContactContaineer, ContentHeader, ContentContainer, LinksContainer, CodeIcon, SocialMediaLinks, SocialMediaLink, SocialMediaElement, GitIconsContainer, GitIcon, ContactHtmlContainer } from './ContactElements';
import GitlabIcon from '../../Assets/svg/gitlab.svg';
import GithubIcon from '../../Assets/svg/github.svg';
import Codeicon from '../../Assets/svg/code.svg';
import NoiseBackground from '../../Components/NoiseBackground';
import PageHeader from '../../Components/PageHeader/PageHeader';
import { motion } from "framer-motion"
import Cover from '../../Components/Cover';
import Typical from 'react-typical'
import BackgroundImage from '../../Components/BackgroundImage';
import background from '../../Assets/svg/background-green.svg';

function Contact() {

    return (
        <ContactHtmlContainer>
            <motion.div>
            <Helmet>
                <style>{'body { background: #03CEA4; }'}</style>
            </Helmet>
            <BackgroundImage background={background}/>
            <NoiseBackground/>
            <ContactContaineer>
                <PageHeader title="LET'S TALK" color="#F97946"/>
                <ContactContaineer >
                    <ContentContainer>
                        <ContentHeader>Interested in</ContentHeader>
                        <ChangingContentText > 
                            <Typical
                                loop={Infinity}
                                wrapper="b"
                                steps={[
                                    1000,
                                    'getting a website?',
                                    1000,
                                    "hiring me?",
                                    1000,
                                    "bringing an idea to life?",
                                    1000,
                                    "just having a chat?",
                                    1000
                                ]}
                            /> 
                        </ChangingContentText>
                    </ContentContainer>
                    <LinksContainer>
                    <SocialMediaLinks>
                        <SocialMediaLink href="mailto: taaviraudkivi2@gmail.com" target="_blank" rel="noreferrer">
                            <SocialMediaElement>email</SocialMediaElement>
                        </SocialMediaLink>
                        <CodeIcon src={Codeicon} />
                        <SocialMediaLink href="https://www.linkedin.com/in/taavi-raudkivi-6a70b7176/?originalSubdomain=ee" target="_blank" rel="noreferrer">
                            <SocialMediaElement>linkedin</SocialMediaElement>
                        </SocialMediaLink>
                        <CodeIcon src={Codeicon} />
                        <SocialMediaLink href="https://www.instagram.com/onu_heinko/" target="_blank" rel="noreferrer">
                            <SocialMediaElement>instagram</SocialMediaElement>
                        </SocialMediaLink>
                    </SocialMediaLinks>
                    </LinksContainer>
                    <GitIconsContainer>
                        <a href="https://gitlab.com/taaviraudkivi2" target="_blank" rel="noreferrer">
                            <GitIcon src={GitlabIcon} as={motion.img} whileHover={{scale: 1.3}}/>
                        </a>
                        <a href="https://github.com/TaaviStone" target="_blank" rel="noreferrer">
                            <GitIcon src={GithubIcon} as={motion.img} whileHover={{scale: 1.3}}/>
                        </a>
                    </GitIconsContainer>
                </ContactContaineer>
            </ContactContaineer>
            <Cover/>
            </motion.div>
        </ContactHtmlContainer>
    )
}

export default Contact
