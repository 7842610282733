import styled from 'styled-components';

export const Switch = styled.div`
    z-index: 0;
    font-family: Candara;
    position: relative;
    width: 84vw;
    height: 8vw;
    background-color: #FFD782;
    border-radius: 20px;
    border: 2px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
        width: 45vw;
        height: 4vw;

    }
    @media (min-width: 1025px) {
        width: 36vw;
        height: 5vh;
        @media (min-height: 1080px) {
            border: 3px solid #000;
        }
    }
`;

export const SwitchRadio = styled.input`
    display: none;
`;

export const SwitchSelection = styled.span`
    display: block;
    position: absolute;
    z-index: 0;
    top: 0.7vw;
    left: 0;
    width: 26vw;
    height: 6vw;
    background: #fff;
    box-shadow: 2px 2px #a97300;
    border-radius: 20px;
    transition: left 0.25s ease-out;

    @media (min-width: 768px) {
        top: 0.5vw;
        width: 14vw;
        height: 3vw;
    }

    @media (min-width: 1025px) {
        width: 11vw;
        height: 4vh;
        top: 0.5vh;
    }
`;

export const SwitchLabel = styled.label`
    position: relative;
    z-index: 2;
    float: left;
    width: 28vw;
    font-size: 15px;
    color: #000;
    text-align: center;
    cursor: pointer;
    ${SwitchRadio}:checked + &{
        transition: 0.15s ease-out;
    }

    @media (min-width: 768px) {
        width: 15vw;
    }

    @media (min-width: 1025px) {
        width: 12vw;
        font-size: 20px;
    }
  
`;
