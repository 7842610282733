import styled from "styled-components";

export const ContactHtmlContainer = styled.html`
`;

export const ContactContaineer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center ;
    flex-direction: column;
    margin-top: 10px;
`;

export const ContentContainer = styled.div`
    font-family: Candara;
    margin-top: -20px;
    letter-spacing: 3px;

    @media (max-width: 375px) {
        margin-top: -50px;
    }

    @media (min-width: 1025px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
    } 
`;

export const ContentHeader = styled.h3`
    font-size: 35px;
    word-spacing: 10px;

    @media (max-width: 375px) {
        font-size: 30px;
    }

    @media (min-width: 768px) {
        font-size: 60px;
    }

    @media (min-width: 1025px) {
        font-size: 70px;
        padding: 0 1vw;
    } 
`;

export const ChangingContentText = styled.p`
    margin-top: -20px;
    color: #fff;
    text-shadow: 2px 3px 5px rgba(0,0,0,0.5);
    font-size: 25px;
    text-decoration: underline;

    @media (max-width: 375px) {
        font-size: 18px;
    }
    
    @media (min-width: 768px) {
        margin-top: -30px;
        font-size: 40px;
    }

    @media (min-width: 1025px) {
        font-size: 50px;
        padding: 0 1vw;
        margin-top: 50px;
    } 
`;

export const LinksContainer = styled.div`
    font-family: Candara;
    margin-top: 50px;
`;

export const SocialMediaLinks = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style-type: none; 
    padding: 0; 
    margin: 0; 
    font-size: 30px;

    @media (min-width: 768px) {
        font-size: 50px;
    }

    @media (min-width: 1025px) {
        display: flex;
        flex-direction: row;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        position: absolute;
        left: 40px;
        top: 50%;
        transform: translateY(-50%);
    }
`;

export const SocialMediaElement = styled.li`
    margin: -25px;
    letter-spacing: 3px;
    padding: 5px 0;

    @media (max-width: 375px) {
        font-size: 25px;
        padding: 8px 0;
    }

    @media (min-height: 720px) {
        padding: 15px 0;
        font-size: 30px;
    }    

    @media (min-height: 750px) {
        padding: 20px 0;
        font-size: 35px;
    }

    @media (min-width: 768px) {
        padding: 15px 0;
        @media (min-height: 750px) {
            padding: 25px 0;
            font-size: 45px;
        }
    }

    @media (min-width: 1025px) {
        transform: rotate(-180deg);
        padding: 20px 0;
        font-weight: 500;

        &::after {
        transform: rotate(90deg);
        background: none repeat scroll 0 0 transparent;
        bottom: 50%;
        content: "";
        display: block;
        height: 4px;
        margin-left: -45px;
        position: absolute;
        background: #000;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
        }
        &:hover:after {
            width: 250%; 
            left: 0; 
        }
    } 
`;

export const SocialMediaLink = styled.a`
    text-decoration: none;
    color: #000;
    cursor: pointer;
`;

export const CodeIcon = styled.img`
    width: 60px;

    @media (max-width: 375px) {
        width: 40px;
        padding: 5px;
    }
    
    @media (min-width: 1025px) {
        margin-right: -10px;
    } 
`;

export const GitIconsContainer = styled.div`
    margin-top: 35px;

    @media (min-width: 768px) {
        margin-top: 60px;
    }

    @media (min-width: 1025px) {
        position: absolute;
        right: 2vw;
        bottom: 5vh;
    } 

    @media (min-width: 768px) {
        @media (min-height: 750px ) {
            margin-top: 60px;
        }
    }
`;

export const GitIcon = styled.img`
    width: 45px;
    padding: 0 30px 0 30px ;
    cursor: pointer;

    @media (max-width: 375px) {
        width: 35px;
    }

    @media (min-height: 750px) {
        width: 55px;
    }

    @media (min-width: 768px) {
        width: 70px;
    }

    @media (min-width: 1025px) {
        width: 60px;
    } 

    @media (min-width: 768px) {
        @media (min-height: 750px ) {
            width: 55px;
        }
    }
`;





