import React from 'react';
import {NavigationContainer, PageLinksUnorderedList, PageLinkListElement, PageLink, PageIconSvg, PageIconTitle} from './BottomNavigationElements';
import HomeIcon from '../../Assets/svg/home.svg';
import PortfolioIcon from '../../Assets/svg/portfolio.svg';
import AboutIcon from '../../Assets/svg/info.svg';
import ContactIcon from '../../Assets/svg/contact.svg';
import { 
    Routes,
    Route,
    NavLink
} from 'react-router-dom'
import Home from '../../Pages/Home/Home';
import MyProjects from '../../Pages/Projects/MyProjects';
import AboutMe from '../../Pages/About/AboutMe';
import Contact from '../../Pages/Contact/Contact';
import {AnimatePresence} from 'framer-motion/dist/es/index';
import estonian from '../../Content/estonian.json';
import english from '../../Content/english.json'


function BottomNavigation(props) {
    return (
        <NavigationContainer>
            <PageLinksUnorderedList>
                <PageLinkListElement>
                    <PageLink as={NavLink} to={"/"}>
                        <PageIconSvg src={HomeIcon} />
                        <PageIconTitle>home</PageIconTitle>
                    </PageLink>
                </PageLinkListElement>
                <PageLinkListElement>
                    <PageLink as={NavLink} to={"/projects"}>
                        <PageIconSvg src={PortfolioIcon} />
                        <PageIconTitle>projects</PageIconTitle>
                    </PageLink>
                </PageLinkListElement>
                <PageLinkListElement>
                    <PageLink as={NavLink} to={"/about"}>
                        <PageIconSvg src={AboutIcon} />
                        <PageIconTitle>about.me</PageIconTitle>
                    </PageLink>
                </PageLinkListElement>
                <PageLinkListElement>
                    <PageLink as={NavLink} to={"/contact"}>
                        <PageIconSvg src={ContactIcon} />
                        <PageIconTitle>contact</PageIconTitle>
                    </PageLink>
                </PageLinkListElement>
            </PageLinksUnorderedList>
            <AnimatePresence>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/projects" element={<MyProjects/>}/>
                    <Route path="/about" element={<AboutMe/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                </Routes>
            </AnimatePresence>
        </NavigationContainer>
    )
}

export default BottomNavigation
