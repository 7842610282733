import React from 'react'
import styled from 'styled-components';
import { motion } from "framer-motion";
import pageTransitionColor from '../Constants/PageTransitionColor'
import NoiseBackground from './NoiseBackground';

function Cover() {
    const Cover = styled.div`
        position:fixed;
        padding:0;
        margin:0;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background: linear-gradient(25deg,#d64c7f,#ee4758 50%);
    `;

    return (
        <div>
            <motion.div initial="show" animate="hidden" exit="exit" transition={{duration: 2}} variants={pageTransitionColor}>
                <motion.div animate={{display: 'none'}} transition={{delay: 2}}>
                    <NoiseBackground/>
                    <Cover/>
                </motion.div>
            </motion.div>
        </div>
    )
}

export default Cover
