import styled from 'styled-components';

let bgColor = "#fff";
let textColor = "#000";
let borderColor = "#000";

export const NavigationContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const  PageLinksUnorderedList = styled.ul`
    z-index: 20;
    display: flex;
    align-items: center;
    position: relative;
    list-style-type: none;
    background-color: ${bgColor};
    height: 50px;
    padding-left: 0;
    border-radius: 20px;
    border: 2px solid ${borderColor};
    position: fixed;
    bottom: 0px;
    margin-bottom: 15px;

    @media (max-width: 360px) {
      height: 45px;
    }

    @media (min-width: 1025px) {
        bottom: 20px;
    } 
`;

export const PageLinkListElement = styled.li`
`;

export const PageLink = styled.a`
    text-decoration: none;
    color: ${textColor};
    white-space: nowrap;
    display: inline-grid;
    place-items: center;
    position: relative;
    z-index: 1;
    width: 80px;

    &.active { 
        font-size: 20px;
        transition: font-size 0.1s ease-in-out;
        color: red;

        @media (max-width: 360px) {
            font-size: 18px;
        }
    }

    @media (max-width: 360px) {
        width: 70px;
    }

    @media (min-width: 768px) {
        width: 90px;
    }

    @media (min-width: 1025px) {
        width: 100px;
    }
`;

export const PageIconSvg = styled.img`
    margin-bottom: -15px;

    
`;

 export const PageIconTitle = styled.p`
    font-family: Candara;
    
 `;