import React from 'react'
import './App.css';
import BottomNavigation from './Components/BottomNavigation/BottomNavigation';
import { useState, useEffect } from 'react'
import PreLoader from './Components/PreLoader';


function App() {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        
        setTimeout(() => {
            setLoading(false)
        }, 4000)
    }, [])

    return ( 
        <div >
            {
            loading?
                <PreLoader/>:
                <BottomNavigation/>
            }
        </div>
    )
}

export default App