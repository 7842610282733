import styled from "styled-components";


export const MyProjectsHtml = styled.html`
  
`;

export const MyProjectContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center ;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 100px;
`;

export const ProjectTypeMenuContainer = styled.div`
  margin-bottom: 30px;
  margin-top: -10px;

  @media (min-width: 768px) {
      font-size: 70px;
    }

    @media (min-width: 1025px) {
      margin-bottom: 35px;
    }
`;

export const CardsContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 5vh;
  justify-content: center;

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 4vw;
    justify-content: center;
  }
`;