import React from 'react';
import {HomeContainerHtml, TextContainer, HomeSubtitleContainer, PageTitle, PageSubtitle} from './HomeElements';
import {Helmet} from 'react-helmet';
import NoiseBackground from '../../Components/NoiseBackground';
import { motion } from "framer-motion"
import Cover from '../../Components/Cover';
import background from '../../Assets/svg/background-white.svg';
import BackgroundImage from '../../Components/BackgroundImage';


function Home(props) {
    return (
        <HomeContainerHtml>
            <motion.div >
            <Helmet>
                <style>{'body { background: #F2F2F2; }'}</style>
            </Helmet>
            <NoiseBackground/>
            <BackgroundImage background={background}/>
                <TextContainer>
                    <PageTitle>taavi. <br/> raudkivi</PageTitle>
                    <HomeSubtitleContainer>
                        <PageSubtitle>$ computer science <br/> undergraduate </PageSubtitle>
                        <PageSubtitle style={{color: "#D72638"}}>$ Triple Macho <br/> manager </PageSubtitle>
                    </HomeSubtitleContainer>
                </TextContainer>
            <Cover/>
            </motion.div>
        </HomeContainerHtml>
        
    )
}

export default Home
