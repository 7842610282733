import React from 'react'
import styled from 'styled-components';

function BackgroundImage(props) {

    const Background = styled.div`
        background-image: url(${props.background}); 
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        z-index: -2;
        position: fixed;
        top: 5vh;
        left: 5vw;
        width: 90vw;
        height: 90vh;
    `;

    return (
        <>
           <Background/> 
        </>
    )
}

export default BackgroundImage
