import styled from "styled-components";

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    align-items: center;
    width: 90vw;
    height: 90vw;
    border-radius: 7px;
    border: 3px solid #000;

    @media (max-width: 375px) {
        height: 105vw;

        @media (min-height: 800px) {
            height: 95vw;
        }
    }

    @media (min-width: 768px) {
        width: 40vw;
        height: 42vw;
    }

    @media (min-width: 1025px) {
        width: 27vw;
        height: 26vw;
        @media (min-height: 1080px) {
            height: 23vw;
        }
    }
`;

export const CardHeader = styled.h3`
    font-size: 30px;
    font-family: Calibri-regular;
    margin-bottom: 0;
    margin-top: 0;
    padding: 15px;
    white-space: nowrap;

    @media (min-width: 768px) {
        font-size: 25px;
    }
`;

export const CardImage = styled.img`
    width: 80vw;
    box-shadow:
   -2px -2px 0 #000,  
    2px -2px 0 #000,
    -2px 2px 0 #000,
     2px 2px 0 #000;

    @media (min-width: 768px) {
        width: 35vw;
    }

    @media (min-width: 1025px) {
        width: 22vw;
    }
`;

export const CardTextContainer = styled.div`
    margin-right: auto;
    text-align: left;

    @media (min-width: 1025px) {
        margin-top: 10px;
    }
`;

export const TypeText = styled.p`
    margin-left: 5vw;
    font-family: Candara;
    font-size: 17px;

    @media (min-width: 768px) {
        margin-left: 2.5vw;
        
    }

    @media (min-width: 1025px) {
        margin-left: 2.5vw;
        font-size: 20px;
    }
`;

export const ProjectLinksContainer = styled.div`
    width: 80vw;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 768px) {
        width: 35vw;
        margin-top: 5px;
    }

    @media (min-width: 1025px) {
        width: 22vw;
        margin-top: 12px;
    }
`;

export const VisitSiteButton = styled.button`
    margin-top: -6px;
    font-family: Candara;
    padding: 5px 25px 5px 25px;
    font-size: 20px;
    border-radius: 15px;
    border-color: #000;
    background-color: #fff;
    box-shadow: 4px 6px #000;
    cursor: pointer;

    @media (min-width: 768px) {
        padding: 3px 22px;
        font-size: 20px;
    }

    @media (min-width: 1025px) {
        padding: 5px 25px;
        font-size: 25px;
    }
`;

export const GitImage = styled.img`
    width: 50px;
    height: 50px;
    margin-left: 20px;
    cursor: pointer;

    @media (min-width: 768px) {
        width: 50px;
        height: 50px;
    }

    @media (min-width: 1025px) {
        width: 60px;
        height: 60px;
    }
`;

export const Underlined = styled.span`
    font-weight: 600;
    border-bottom: 1.5px solid #000
`;