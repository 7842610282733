const pageTransitionFade = {
    hidden: {
        opacity: 0,
        
    },
    show: {
        opacity: 1,
        
    },
    exit: {
        visibility: 'hidden'
    }
}

export default pageTransitionFade;