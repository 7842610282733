import styled from 'styled-components';



let shadowColor = "#D72638";

export const HomeContainerHtml = styled.div`
`;

export const TextContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 10vh;
    margin-left: 15px;

    @media (max-width: 330px) {
        
    }

    @media (min-width: 768px) {
        margin-left: 40px;
    }

    @media (min-width: 1025px) {
        flex-direction: row;
    }
`;


export const PageTitle = styled.h1`
    color: white;
    font-family: Calibri-regular;
    font-size: 90px;
    word-spacing: 100vw;
    text-shadow: -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000,-5px 5px ${shadowColor}, -6px 6px #000, 
                -4px 4px #000;
    margin-bottom: 10px;
    line-height: 1;

    @media (max-width: 330px) {
        font-size: 70px;
    }

    @media (min-width: 768px) {
        font-size: 150px;
        margin-bottom: 30px;
    }

    @media (min-width: 1025px) {
        font-size: 170px;
        word-spacing: 100vw;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 35%;
        left: 30%;
    }
`;

export const HomeSubtitleContainer = styled.div`
    @media (min-width: 1025px) {
        display: flex;
        flex-direction: column;
        position: absolute;
        transform: translate(50%, -50%);
        top: 45%;
        right: 30%;
    }
`;

export const PageSubtitle = styled.h3`
    margin-bottom: -20px;
    font-family: Candara;
    font-weight: 500;
    font-size: 33px;
    letter-spacing: 2px;

    @media (max-width: 330px) {
        font-size: 25px;
    }

    @media (min-width: 768px) {
        font-size: 40px;
    }

    @media (min-width: 1025px) {
        font-size: 50px;
    }
`;