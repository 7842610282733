const pageTransitionColor = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    }
}

export default pageTransitionColor;