import React from 'react'
import { AboutMeText, AboutMeTextbox, SpecialText } from './AboutTextBoxElements'

function AboutTextBox(props) {

    const alignLeft = {
        textAlign: "left",
        paddingLeft: "10px",
        borderLeft: "5px solid #000"
    }

    const alignRight = {
        textAlign: "right",
        paddingRight: "10px",
        borderRight: "5px solid #000"
    }

    function alignSide() {
        if (props.id % 2 === 0) {
            return (<AboutMeText style={alignRight}> 
            {props.content1}
            <SpecialText style={{zIndex:0}}>{props.special}</SpecialText>
            {props.content2}
        </AboutMeText>)
        } else {
            return (<AboutMeText style={alignLeft} > 
                {props.content1}
                <SpecialText>{props.special}</SpecialText>
                {props.content2}
            </AboutMeText>)
        }
    }

    return (
        <AboutMeTextbox>
            {alignSide()}
        </AboutMeTextbox>
    )
}

export default AboutTextBox
