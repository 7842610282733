import styled from "styled-components";

export const AboutMeTextbox = styled.div`
    z-index: 0;
    display: flex;
    justify-content: center;
    width: 80vw;
    padding: 5vh 8vw;

    @media (min-width: 768px) {
        
    }
  
    @media (min-width: 1025px) {
      width: 50vw;
      padding: 0;
    }   

    @media (min-width: 1023px) {
      @media (min-height: 1300px) {
          width: 40vw;
          padding: 20px;
      }
    } 
`;

export const AboutMeText = styled.p`
    font-family: Candara;
    font-size: 25px;
    font-weight: 300;
    white-space: normal;
    line-height: 130%;

    @media (max-width: 360px) {
      font-size: 20px;
    }

    @media (min-width: 768px) {
        font-size: 35px;
    }
  
    @media (min-width: 1025px) {
      font-size: 35px;
    }   
`;

export const SpecialText = styled.span`
    color: white;
    font-weight: 600;
    background-color: #000;
    padding: 2px;
    white-space: nowrap;
`;
