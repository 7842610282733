import React from 'react'
import LottieLoader from 'react-lottie-loader'
import preload from '../Assets/json/lf30_editor_ikp24cxz.json'
import styled from 'styled-components'
import {Helmet} from 'react-helmet';
import { motion } from "framer-motion"
import NoiseBackground from './NoiseBackground';

function PreLoader() {
    const PreloaderContainer = styled(motion.div)`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        border-radius: 100px;

        @media (min-width: 1025px) {
            width: 40%;
        }

        background: linear-gradient(270deg, #d64c7f, #ee4758, #be527a, #d64c7f);
        background-size: 600% 600%;

        -webkit-animation: AnimationName 1s ease infinite;
        -moz-animation: AnimationName 1s ease infinite;
        animation: AnimationName 1s ease infinite;
        
        @-webkit-keyframes AnimationName {
            0%{background-position:0% 50%}
            50%{background-position:100% 50%}
            100%{background-position:0% 50%}
        }
        @-moz-keyframes AnimationName {
            0%{background-position:0% 50%}
            50%{background-position:100% 50%}
            100%{background-position:0% 50%}
        }
        @keyframes AnimationName {
            0%{background-position:0% 50%}
            50%{background-position:100% 50%}
            100%{background-position:0% 50%}
        }
    `;

    return (
        <>
            <NoiseBackground/>
            <PreloaderContainer initial={{opacity: 1}} animate={{opacity: 0}} transition={{delay: 3}}>
                <Helmet>
                    <style>{'body { background: linear-gradient(25deg,#d64c7f,#ee4758 50%); }'}</style>
                </Helmet>
                <LottieLoader animationData={preload}/>
            </PreloaderContainer>
        </>
    )
}

export default PreLoader
