import React from 'react'
import styled from 'styled-components';
import { motion } from "framer-motion"

const Header = styled.h1.attrs(props => ({
    type: "text",
    color: props.color || "#fff",
}))`
    z-index: 0;
    font-family: Calibri-regular;
    white-space: nowrap;
    font-weight: 800;
    font-size: 60px;
    text-shadow:
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000,
    4px 3px #000;
    color: ${props => props.color};

    @media (min-width: 768px) {
      font-size: 70px;
    }

    @media (min-width: 1025px) {
      font-size: 95px;
      margin-bottom: 5vh;
      margin-top: 3vh;
    }
`;

function PageHeader(props) {
    return (
        <Header color={props.color} as={motion.h1} 
        whileHover={{
            scale: 1.1,
            color: "#fff",
            transition: { duration: 1 },
          }}
        whileTap={{ 
            scale: 0.8,
            color: "#fff",
            transition: { duration: 1 }, }} 
        >
            {props.title}
        </Header>
    )
}

export default PageHeader